.staffDetail {
  padding: 5px;
}

.header {
  padding: 5px 7px;
  font-weight: bold;
}

.divider {
  width: calc(100% - 10px);
  margin: 0 auto;
}

.staffList {
  padding: 5px;
}
