.toolbar {
  background-color: royalblue;
  width: 100%;
  position: fixed;
}

.right {
  display: inline-block;
  margin-left: auto;
  margin-right: 40px;
}

.rightItem {
  margin-right: 0;
}

.appbar {
  position: relative;
  z-index: 1201;
}

.logo {
  height: 48px;
  cursor: pointer;
}

.name {
  padding: 0 10px;
  cursor: pointer;
}
