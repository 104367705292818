.error {
  height: 100vh;
}

.myne {
  padding-top: 2vh;
  display: block;
  margin: 0 auto;
  padding-top: 20vh;
  max-width: 249px;
}

.buttons {
  width: 100%;
  display: block;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.button {
  margin: 0 1vw;
}