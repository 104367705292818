.list {
  width: 225px;
}

.drawerPaper {
  top: 100;
}

.ListItem {
  border-radius: 50%;
}
