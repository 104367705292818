.card {
  width: 25%;
  border: 1px solid #aaa;
}

.pointer {
  cursor: pointer;
}

.coverImage {
  height: 100%;
  display: inline-block;
}

.imgTransform {
  transform: scale(1.1);
  transition: transform 500ms ease-in-out;
}

.leftCard {
  border-radius: 4px 0 0 4px;
  border-right: 0;
}

.centerCard {
  border-radius: 0;
  border-right: 0;
  border-left: 0;
}

.rightCard {
  border-radius: 0 4px 4px 0;
  border-left: 0;
}

.bottomButton {
  height: 100%;
  width: 101%;
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgba(254, 252, 250, 0) 100%
  );
  position: relative;
}

.moveButtonUp {
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
}

.seasonIcon {
  width: 30%;
}

.buttonText {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.buttonTextSeason {
  font-size: 1.5vw;
  font-weight: bolder;
}

@media only screen and (max-width: 800px) {
  .bottomButton {
    transform: translateY(-100%);
    transition: transform 300ms ease-in-out;
  }

  .seasonIcon {
    width: 50%;
  }

  .buttonTextSeason {
    font-size: 3vw;
    font-weight: bolder;
  }
}
