.about {
    margin-top: 5px;
    width: calc(100% - 2vw);
}

.badges {
  margin: 10px 0 5px 5px;
}

.badge {
  padding-right: 0.5vw;
}

.header,
.header2,
.paragraph {
  padding-left: 5px;
}

.paragraph {
  padding-top: 5px;
  padding-bottom: 5px;
}

.notice {
  border-left: 4px solid #3f51b5;
  padding: 2px;
  background-color: #eee;
  display: block;
  max-width: 800px;
}

.apiList {
  margin: 0;
  list-style-type: circle;
}
