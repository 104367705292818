.titleSection {
  position: fixed;
  display: inline-block;
  height: 300px;
  width: calc(100% - 25px);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.625) 50%,
    rgba(255, 255, 255, 0) 50%
  );
  border-radius: 2px;
}

.coverImage {
  display: inline-block;
  margin-left: 4vw;
  width: 194px;
  height: 291px;
  object-fit: cover;
  position: relative;
  border: double 4px transparent;
  border-radius: 2px;
  background-image: linear-gradient(white, white),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 49.8%,
      rgba(255, 255, 255, 0.625) 49.8%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.titleInfo {
  vertical-align: top;
  display: inline-block;
  width: calc(100% - 194px - 6vw);
  height: 100%;
}

.basicInfo {
  white-space: nowrap;
  height: 50%;
  padding-left: 1vw;
  color: white;
}

.favIcon {
  color: rgb(246, 50, 89);
  vertical-align: text-bottom;
}

.icon {
  vertical-align: top;
  padding-right: 5px;
  opacity: 0.5;
}

.rating {
  vertical-align: text-bottom;
  padding-right: 10px;
}

.links {
  position: absolute;
  display: block;
  bottom: 50%;
}

.link {
  cursor: pointer;
}

.summaryInfo {
  height: 50%;
}

.summary {
  padding-left: 1vw;
  padding-top: 1vh;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .titleSection {
    height: 154px;
    width: calc(100% - 20px);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.625) 80%,
      rgba(255, 255, 255, 0) 80%
    );
    padding-top: 4px;
    padding-bottom: 144px;
  }

  .titleCN,
  .titleJP {
    color: white;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .favIcon {
    position: absolute;
    right: 0;
  }

  .coverImage {
    margin-left: 2vw;
    width: 100px;
    height: 150px;
    border: 0;
  }

  .titleInfo {
    vertical-align: top;
    display: inline-block;
    width: calc(100% - 100px - 2vw);
  }

  .basicInfo {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    height: 100%;
    padding-left: 10px;
    color: black;
  }

  .links {
    position: absolute;
    display: block;
    bottom: 50%;
    white-space: pre-wrap;
  }

  .link {
    display: inline-block;
  }

  .summaryInfo {
    height: 50%;
    position: absolute;
    margin-left: calc(-100px - 1vw);
    margin-right: 1vw;
  }

  .summary {
    padding-top: 0.5vw;
    border-top: 1px solid #aaa;
  }
}

@media screen and (max-width: 480px) {
  .titleSection {
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.625) 86%,
      rgba(255, 255, 255, 0) 86%
    );
  }

  .titleCN {
    font-size: 1rem;
  }

  .titleJP,
  .links,
  .time,
  .bangumiRating,
  .summary {
    font-size: 0.7rem;
  }

  .rating {
    font-size: 0.9rem;
    vertical-align: text-top;
  }

  .summary {
    -webkit-line-clamp: 8;
  }
}
@media screen and (max-width: 400px) {
  .titleSection {
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.625) 86%,
      rgba(255, 255, 255, 0) 86%
    );
  }

  .titleCN {
    font-size: 1rem;
  }

  .titleJP,
  .links,
  .time,
  .bangumiRating {
    font-size: 0.7rem;
  }

  .rating {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 375px) {
  .titleSection {
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.625) 88%,
      rgba(255, 255, 255, 0) 88%
    );
  }

  .titleCN {
    font-size: 0.8rem;
  }

  .titleJP,
  .links,
  .time,
  .bangumiRating {
    font-size: 0.625rem;
  }

  .rating {
    font-size: 0.8rem;
  }
}
