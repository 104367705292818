.loading {
    width: 100vw;
    height: 100vh;
}

.logo {
    padding-top: 20vh;
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

/** https://codepen.io/FrankieDoodie/pen/dgVGad */
.loadingText {
    position: relative;
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 4px;
    overflow: hidden;
    padding-top: 5vh;
    background: linear-gradient(90deg, rgba(0,174,239,1), rgba(0,174,239,1), rgba(0,78,108,1));
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 3s linear infinite;
    background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
    0% {
      background-position: -200%;
    }
    100% {
      background-position: 200%;
    }
  }