.column {
  width: 13%;
  min-width: 200px;
  display: inline-table;
  margin: 1vh 0.3% 0 0.3%;
}

.icon {
  display: inline-block;
  opacity: 0.9;
}

.weekday {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}

@media only screen and (max-width: 480px) {
  .column{
    width: 95%;
  }
}
