.characterCard {
  width: 49%;
  min-width: 360px;
  height: 200px;
  margin: 0.5%;
  background-color: rgba(255, 255, 255, 0.375);
  display: inline-flex;
  position: relative;
}

.characterImage {
  height: 200px;
  width: 120px;
  display: inline-flex;
  background-position: center top;
}

.charaInfo {
  width: calc(100% - 120px);
  padding-left: 5px;
}

.role {
  border: 1px solid rgb(63, 81, 181);
  border-radius: 2px;
  background-color: rgba(63, 81, 181);
  color: white;
  margin: 0 2px;
  padding: 0 1px;
}

.actors {
  border: 1px solid rgb(63, 81, 181);
  border-radius: 2px;
  background-color: white;
  color: rgba(63, 81, 181);
  margin: 0 2px;
  padding: 0 1px;
}

@media screen and (max-width: 800px) {
  .characterCard {
    width: 49%;
    min-width: 300px;
    margin: 0.5%;
  }
}

@media screen and (max-width: 700px) {
  .characterCard {
    width: 100%;
    min-width: 300px;
    margin: 0.5%;
  }
}