.ratingDetail {
  padding: 5px;
}

.header {
  padding: 5px 7px;
  font-weight: bold;
}

.divider {
  width: calc(100% - 10px);
  margin: 0 auto;
}

.ratingChart {
  padding: 5px;
}

.ratingNum {
  display: inline-flex;
  width: 10%;
}

.bar {
  display: inline-flex;
  padding: 2px 0;
  width: 90%;
}
