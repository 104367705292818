.tagsDetail {
  padding: 5px;
}

.header {
  padding: 5px 7px;
  font-weight: bold;
}

.divider {
  width: calc(100% - 10px);
  margin: 0 auto;
}

.tags {
    padding: 5px;
}

.tag {
  display: inline-block;
  margin: 2px;
  padding: 1px 5px;
  border: 1px solid rgba(63, 81, 181, 0.5);
  border-radius: 2px;
}
