.home {
  margin-top: 64px;
  height: calc(100vh - 64px);
  user-select: none;
}

.logo {
  max-width: 20vh;
  padding-top: 5vh;
  display: block;
  margin: 0 auto;
}

.title {
  background: linear-gradient(0deg, rgba(0,78,108,1) 36%, rgba(0,174,239,1) 36%); 
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.arrowIcon {
  vertical-align: bottom;
  animation: bounce 2s infinite;
}

.badges {
  margin-top: 2vh;
  text-align: center;
}

.myne {
  padding-top: 2vh;
  display: block;
  margin: 0 auto;
  width: 5vw;
  max-width: 253px;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
  60% {
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
