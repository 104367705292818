.header {
  padding: 5px 7px;
  font-weight: bold;
}

.divider {
  width: calc(100% - 10px);
  margin: 0 auto;
}

.productionDetail {
  padding: 5px 5px 0 5px;
}

.parsedInfo {
  position: relative;
  padding: 0 7px;
}

.parsedInfo::after {
  content: "";
  height: 1px;
  width: 98%;
  background: #000;
  opacity: 0.2;
  position: absolute;
  left: calc(50% - 49%);
  bottom: -1px;
}
