.staffCard {
  width: 32%;
  min-width: 300px;
  margin: 0.5%;
  display: inline-flex;
  background-color: rgba(255, 255, 255, 0.375);
  height: 96px;
  float: left;
}

.profileImage {
  height: 96px;
  width: 96px;
  cursor: pointer;
}

.staffInfo {
  width: calc(100% - 96px);
  padding-left: 3px;
}

.job {
  margin: 0 2px;
  padding: 0 1px;
  border: 1px solid rgb(63, 81, 181);
  border-radius: 2px;
  background-color: rgba(63, 81, 181);
  color: white;
}

@media screen and (max-width: 800px) {
  .staffCard {
    width: 32%;
    min-width: 200px;
    margin: 0.5%;
  }
}

@media screen and (max-width: 700px) {
  .staffCard {
    width: 49%;
  }
}

@media screen and (max-width: 480px) {
  .staffCard {
    width: 100%;
  }
}
