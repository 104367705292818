.contentsArea {
  margin-top: 72px;
  width: calc(100% - 250px);
  transform: translateX(250px);
  transition: transform 225ms ease-out 0ms;
}

.contentsAreaFullWidth {
  margin-top: 72px;
  width: calc(100% - 20px);
  transform: translateX(20px);
  transition: transform 225ms ease-in 0ms;
}

@media only screen and (max-width: 480px) {
  .contentsAreaFullWidth {
    margin-top: 64px;
    transform: translateX(10px);
  }
}